import React from 'react';

import { GatsbyImage } from 'gatsby-plugin-image';

import { Wrapper, Row } from './Hero.styles';

const Hero = (props) => {
  //PROPS
  const { image, title } = props;
  //PROPS

  return (
    <Wrapper>
      <GatsbyImage
        image={image?.localFile?.childImageSharp?.gatsbyImageData}
        alt={image?.altText}
      />
    </Wrapper>
  );
};

export default Hero;
