import React from 'react';

import HTMLReactParser from 'html-react-parser';
import { GatsbyImage } from 'gatsby-plugin-image';

import { Wrapper, Row } from './Banner.styles';

const Banner = (props) => {
  //PROPS
  const {
    data: { linha },
  } = props;
  //PROPS

  return (
    <Wrapper>
      <Row>
        {linha.map((item, index) => {
          if ('texto' in item) {
            return (
              <div className="col col--text" key={index}>
                {item.titulo && <h1>{item.titulo}</h1>}
                {item.texto && HTMLReactParser(item.texto)}
              </div>
            );
          } else {
            return (
              <div className="col" key={index}>
                <GatsbyImage
                  image={
                    item.imagem?.localFile?.childImageSharp?.gatsbyImageData
                  }
                  alt={item.imagem?.altText}
                />
              </div>
            );
          }
        })}
      </Row>
    </Wrapper>
  );
};

export default Banner;
