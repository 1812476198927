import styled from 'styled-components';

import { container } from 'theme/sizes';
import { toRem } from 'utils/mixins';

export const Wrapper = styled.div`
  position: relative;
  max-width: ${container.default};
  width: calc(100% - 2.5rem);
  margin: ${toRem(80)} auto;
`;
