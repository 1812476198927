import React from 'react';

// import flexible content modules
import Banner from './banner';
import ProductsGrid from 'components/productsGrid';

const DynaContentAmb = (props) => {
  //PROPS
  const { rows } = props;
  //PROPS

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  const Linha = Banner;
  const Produtos = ProductsGrid;

  if (!!rows) {
    return rows
      .filter((o) => o !== null)
      .map((e, index) => {
        const type = capitalizeFirstLetter(Object.keys(e)[0]);

        console.log(e.produtos);
        const components = {
          Linha,
          Produtos,
        };
        const Component = components[type];
        if (type === 'Produtos') {
          return Component && <Component key={index} data={e.produtos} />;
        }
        return Component && <Component key={index} data={e} />;
      });
  } else {
    return null;
  }
};

export default DynaContentAmb;
