import React from 'react';

import { graphql } from 'gatsby';

import DynaContentAmb from 'components/dcontentamb';
import Hero from 'components/hero';
import FavComponent from 'components/favComp';

const AmbientePage = (props) => {
  //PROPS
  const {
    data: {
      ambiente: {
        ambientes: { pagina, thumbnailImage },
        title,
        seo,
      },
    },
  } = props;
  //PROPS

  console.log(pagina, title);

  return (
    <>
      <FavComponent slug={seo.slug} amb />
      <Hero image={thumbnailImage} title={title} />
      <DynaContentAmb rows={pagina} />
    </>
  );
};

export default AmbientePage;

export const query = graphql`
  query Ambiente($id: String) {
    ambiente: wpPost(id: { eq: $id }) {
      title
      id
      slug
      seo {
        slug
      }
      ambientes {
        thumbnailImage {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
        pagina {
          ... on WpPost_Ambientes_Pagina_Produtos {
            produtos {
              ... on WpProduto {
                seo {
                  slug
                }
                product {
                  text
                  title
                  imagens {
                    altText
                    localFile {
                      childImageSharp {
                        gatsbyImageData(layout: FULL_WIDTH, aspectRatio: 1)
                      }
                    }
                  }
                  imagem {
                    altText
                    localFile {
                      childImageSharp {
                        gatsbyImageData(layout: FULL_WIDTH, aspectRatio: 1)
                      }
                    }
                  }
                }
              }
            }
          }
          ... on WpPost_Ambientes_Pagina_Linha {
            linha {
              ... on WpPost_Ambientes_Pagina_Linha_Linha_Imagem {
                imagem {
                  altText
                  localFile {
                    childImageSharp {
                      gatsbyImageData(layout: FULL_WIDTH)
                    }
                  }
                }
              }
              ... on WpPost_Ambientes_Pagina_Linha_Linha_BlocoTexto {
                texto
                titulo
                link {
                  target
                  title
                  url
                }
              }
            }
          }
        }
      }
    }
  }
`;
